import React from "react"

import Layout from "../components/layout"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faIdCard} from '@fortawesome/free-solid-svg-icons'
import {faGithub, faLinkedin, faXing} from "@fortawesome/free-brands-svg-icons";
import SEO from "../components/seo";
import {Link} from "gatsby";

const IndexPage: React.FC = () => (
    <Layout>
        <SEO/>
        <div className="vita">
            <div className="wrapper">
                <div className="main">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="align-bottom"><h1>Tommy Seus</h1> <p> Dipl.-Ing. (FH)
                                Medientechnik<br/> Softwareentwickler</p></div>
                        </div>
                        <div className="col-md-3">
                            <div className="btn-group-vertical">
                                <a className="btn btn-light" role="button" target="_blank" rel="noreferrer"
                                   href="https://www.xing.com/profile/Tommy_Seus"><FontAwesomeIcon
                                    icon={faXing} fixedWidth/> Xing</a>
                                <a
                                    className="btn btn-light" role="button" target="_blank" rel="noreferrer"
                                    href="https://www.linkedin.com/in/tommy-seus"><FontAwesomeIcon
                                    icon={faLinkedin} fixedWidth/> LinkedIn</a><a
                                className="btn btn-light" role="button" target="_blank" rel="noreferrer"
                                href="https://github.com/tommyseus"><FontAwesomeIcon icon={faGithub} fixedWidth/> GitHub</a><a
                                className="btn btn-light" role="button" target="_blank" rel="noreferrer"
                                href="https://www.thingiverse.com/tommyseus/about"><FontAwesomeIcon
                                icon={faIdCard} fixedWidth/> Thingiverse</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer"> {(new Date()).getFullYear()} · tommy-seus.de · <Link
                    to={'/data-protection'}>Datenschutzerklärung</Link></div>
            </div>
            <div className="loading"/>
        </div>
    </Layout>
)

export default IndexPage
